<template>
  <div class="container">
    <h2 class="mb-2">Impressum</h2>

    <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
    <p>Jonas Dippel, Michael Perk GbR<br/>
      Auguststraße 19<br/>
      38100 Braunschweig</p>

    <h3>Kontakt</h3>
    <p>
      Michael Perk<br/>
      Auguststraße 19<br/>
      38100 Braunschweig<br/>
      <a href="mailto:politrack@gmx.de">politrack@gmx.de</a>
    </p>

    <h3>Haftungsausschluss</h3>
    <p>
      Bitte beachten Sie die wichtigen rechtlichen Hinweise zum Inhalt, zur Verfügbarkeit, zum
      Urheberrecht und zu externen Links auf dieser Website.
    </p>

    <h3>Inhalt der Website</h3>
    <p>
      Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. Die Ersteller übernehmen jedoch kein
      Gewähr
      für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte.
    </p>

    <h3>Verfügbarkeit der Website</h3>
    <p>
      Die Ersteller werden sich bemühen, den Dienst möglichst unterbrechungsfrei zum Abruf anzubieten. Auch bei aller
      Sorgfalt können aber Unterbrechungen nicht vollständig ausgeschlossen werden. Die Autoren behalten sich das Recht
      vor, ihr Angebot jederzeit zu ändern oder einzustellen. Für Unterbrechungen oder sonstige Störungen, die durch
      nicht
      ordnungsgemäß verlinkte Dateien oder nicht ordnungsgemäß strukturierte Formate entstehen, wird keine Haftung
      übernommen.
    </p>
  </div>
</template>

<script>
export default {
  name: "Imprint",
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>