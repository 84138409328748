<template>
  <div class="container">
    <h1>Datenschutzerklärung</h1>
    <h2 id="einleitung-ueberblick">Einleitung und Überblick</h2>
    <p>Wir haben diese Datenschutzerklärung (Fassung 20.09.2021-311831659) verfasst, um Ihnen gemäß der Vorgaben der <a

        href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311831659" target="_blank"
        rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären,
      welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die von uns beauftragten
      Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen
      Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br/>
      <strong>Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über
      Sie
      verarbeiten.</p>
    <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese
      Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben.
      Soweit es der Transparenz förderlich ist, werden technische <strong>Begriffe
        leserfreundlich erklärt</strong>, <strong>Links</strong> zu weiterführenden
      Informationen geboten und <strong>Grafiken</strong> zum Einsatz gebracht. Wir
      informieren
      damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene
      Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn
      man
      möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind,
      wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und
      vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br/>
      Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche
      Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere
      Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
    <h2 id="anwendungsbereich">Anwendungsbereich</h2>
    <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für
      alle
      personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen
      Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und
      postalische
      Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und
      Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser
      Datenschutzerklärung umfasst:</p>
    <ul>
      <li>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
      <li>Social Media Auftritte und E-Mail-Kommunikation</li>
      <li>mobile Apps für Smartphones und andere Geräte</li>
    </ul>
    <p>
      <strong>Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen
      personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir
      außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert
      informieren.</p>
    <h2 id="rechtsgrundlagen">Rechtsgrundlagen</h2>
    <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen
      und
      Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene
      Daten
      zu verarbeiten.<br/>
      Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES
      RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf
      EUR-Lex,
      dem Zugang zum EU-Recht, unter <a
          href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311831659"
          target="_blank" rel="noopener">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</a>
      nachlesen.</p>
    <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
    <ol>
      <li>
        <strong>Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre
        Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer
        eingegebenen Daten eines Kontaktformulars.
      </li>
      <li>
        <strong>Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder
        vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen
        Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.
      </li>
      <li>
        <strong>Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir
        einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich
        verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.
      </li>
      <li>
        <strong>Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle
        berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung
        personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und
        wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.
      </li>
    </ol>
    <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt
      sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche
      Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
    <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
    <ul>
      <li>In <strong>Österreich</strong> ist dies das Bundesgesetz
        zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong
        >Datenschutzgesetz</strong>),
        kurz <strong>DSG</strong>.
      </li>
      <li>In <strong>Deutschland</strong> gilt das <strong
      >Bundesdatenschutzgesetz</strong>, kurz<strong>
        BDSG</strong>.
      </li>
    </ul>
    <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden
      Abschnitten darüber.</p>
    <h2 id="kontaktdaten-verantwortliche">Kontaktdaten des Verantwortlichen</h2>
    <p>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person
      bzw.
      Stelle:<br/>
      <span style="font-weight: 400">Jonas Dippel, Michael Perk GbR<br/>
Auguststraße 19, 38100 Braunschweig, Deutschland</span>
      <br/>
      Vertretungsberechtigt: Michael Perk<br/>
      E-Mail: <a href="mailto:politrack@gmx.de">politrack@gmx.de</a>
      <br/>
      <router-link :to="{name: 'imprint'}">Impressum</router-link>
    </p>
    <h2 id="speicherdauer">Speicherdauer</h2>
    <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen
      und
      Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene
      Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir
      gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum
      Beispiel
      zu Zwecken der Buchführung.</p>
    <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die
      Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
    <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere
      Informationen dazu haben.</p>
    <h2 id="rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</h2>
    <p>Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten
      Verarbeitung
      von Daten kommt:</p>
    <ul>
      <li>Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen
        verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden
        Informationen zu erfahren:
        <ul>
          <li>zu welchem Zweck wir die Verarbeitung durchführen;</li>
          <li>die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
          <li>wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden,
            wie
            die Sicherheit garantiert werden kann;
          </li>
          <li>wie lange die Daten gespeichert werden;</li>
          <li>das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der
            Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;
          </li>
          <li>dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen
            Behörden finden Sie weiter unten);
          </li>
          <li>die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
          <li>ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden,
            um
            zu einem persönlichen Profil von Ihnen zu gelangen.
          </li>
        </ul>
      </li>
      <li>Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet,
        dass wir Daten richtig stellen müssen, falls Sie Fehler finden.
      </li>
      <li>Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
        Vergessenwerden“),
        was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.
      </li>
      <li>Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was
        bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.
      </li>
      <li>Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet,
        dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.
      </li>
      <li>Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung
        eine
        Änderung der Verarbeitung mit sich bringt.
        <ul>
          <li>Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches
            Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert,
            können
            Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem
            Widerspruch rechtlich nachkommen können.
          </li>
          <li>Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit
            gegen
            diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing
            verwenden.
          </li>
          <li>Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen
            diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling
            verwenden.
          </li>
        </ul>
      </li>
      <li>Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer
        ausschließlich
        auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.
      </li>
    </ul>
    <p>
      <strong>Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben
      gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
    <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
      datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der
      Aufsichtsbehörde
      beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a
          href="https://www.dsb.gv.at/?tid=311831659"
          target="_blank"
          rel="noopener">https://www.dsb.gv.at/</a>
      finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können
      Sie sich an die <a href="https://www.bfdi.bund.de" target="_blank" rel="noopener">Bundesbeauftragte
        für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende
      lokale
      Datenschutzbehörde zuständig:</p>
    <h2 id="sicherheit-datenverarbeitung">Sicherheit der Datenverarbeitung</h2>
    <p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt.
      Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im
      Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen
      schließen können.</p>
    <p>Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche
      Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B.
      Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls
      erforderlich, noch auf konkrete Maßnahmen ein.</p>
    <h2 id="tls-verschluesselung-https">TLS-Verschlüsselung mit https</h2>
    <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext
      Transfer
      Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu
      übertragen.<br/>
      Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist
      &#8211; niemand kann &#8220;mithören&#8221;.</p>
    <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch Technikgestaltung
      <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311831659"
          target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer
      Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können wir den Schutz
      vertraulicher Daten sicherstellen.<br/>
      Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img"
                                                                                                       src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg"
                                                                                                       width="17"
                                                                                                       height="18"/>
      links
      oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https
      (anstatt http) als Teil unserer Internetadresse.<br/>
      Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach &#8220;Hypertext
      Transfer Protocol Secure wiki&#8221; um gute Links zu weiterführenden Informationen zu erhalten.</p>
    <h2 id="cookies">Cookies</h2>
    <table border="1" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong>Cookies Zusammenfassung</strong>
          <br/>
          &#x1f465; Betroffene: Besucher der Website<br/>
          &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
          Hersteller
          der Software, der das Cookie setzt.<br/>
          &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie weiter
          unten bzw. beim Hersteller der Software, der das Cookie setzt.<br/>
          &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren variieren<br/>
          &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO
          (Berechtigte Interessen)
        </td>
      </tr>
      </tbody>
    </table>
    <h3>Was sind Cookies?</h3>
    <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br/>
      Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
      Datenschutzerklärung besser verstehen.</p>
    <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
      Chrome,
      Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine Text-Dateien in Ihrem
      Browser. Diese Dateien nennt man Cookies.</p>
    <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden
      Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche
      gibt.
      HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese
      Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht.
      Ein
      Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere
      Attribute angegeben werden.</p>
    <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen.
      Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite
      zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind.
      In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in
      einer einzigen Datei gespeichert.</p>
    <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver.
      Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser erneut
      verwendet, sobald eine andere Seite angefordert wird.</p>
    <p>
      <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg"
           alt="HTTP Cookie Interaktion zwischen Browser und Webserver" class="img-fluid"/>
    </p>
    <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
      unserer
      Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt. Jedes Cookie
      ist
      individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von
      ein
      paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner
      oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</p>
    <p>So können zum Beispiel Cookie-Daten aussehen:</p>
    <p>
      <strong>Name:</strong> _ga<br/>
      <strong>Wert:</strong> GA1.2.1326744211.152311831659-9<br/>
      <strong>Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br/>
      <strong>Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
    <ul>
      <li>Mindestens 4096 Bytes pro Cookie</li>
      <li>Mindestens 50 Cookies pro Domain</li>
      <li>Mindestens 3000 Cookies insgesamt</li>
    </ul>
    <h3>Welche Arten von Cookies gibt es?</h3>
    <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den
      folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen
      Arten von HTTP-Cookies eingehen.</p>
    <p>Man kann 4 Arten von Cookies unterscheiden:</p>
    <p>
      <strong>Unerlässliche Cookies<br/>
      </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es
      diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später
      erst
      zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster
      schließt.</p>
    <p>
      <strong>Zweckmäßige Cookies<br/>
      </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem
      werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern
      gemessen.
    </p>
    <p>
      <strong>Zielorientierte Cookies<br/>
      </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte,
      Schriftgrößen oder Formulardaten gespeichert.</p>
    <p>
      <strong>Werbe-Cookies<br/>
      </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste
      Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
    <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie zulassen
      möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
    <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a
        href="https://tools.ietf.org/html/rfc6265" target="_blank" rel="nofollow noopener">https://tools.ietf.org/html/rfc6265</a>,
      dem Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management Mechanism&#8221;.
    </p>
    <h3>Zweck der Verarbeitung über Cookies</h3>
    <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
      Hersteller der Software, die das Cookie setzt.</p>
    <h3>Welche Daten werden verarbeitet?</h3>
    <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden,
      kann
      man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung über die
      verarbeiteten bzw. gespeicherten Daten informieren.</p>
    <h3>Speicherdauer von Cookies</h3>
    <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach
      weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
    <p>Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies
      jederzeit
      manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die auf einer
      Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der
      Speicherung
      bis dahin unberührt bleibt.</p>
    <h3>Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3>
    <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
      Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur
      teilweise
      zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
    <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen
      ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
    <p>
      <a href="https://support.google.com/chrome/answer/95647?tid=311831659" target="_blank"
         rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
    </p>
    <p>
      <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311831659"
         target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
    </p>
    <p>
      <a
          href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311831659"
          target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
        Ihrem
        Computer abgelegt haben</a>
    </p>
    <p>
      <a
          href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311831659"
          target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
    </p>
    <p>
      <a
          href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311831659" target="_blank"
          rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
    </p>
    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
      informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das
      Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung
      in
      Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines
      Chrome Browsers.</p>
    <h3>Rechtsgrundlage</h3>
    <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies
      eine
      <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt.
      Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In
      Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In
      Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung
      dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>
    <p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt. bestehen <strong
    >berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten
      Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme Benutzererfahrung
      bescheren
      und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
    <p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung.
      Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
    <p>In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte
      Software Cookies verwendet.</p>
    <h2 id="webhosting">Webhosting</h2>
    <table border="1" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong>Webhosting Zusammenfassung</strong>
          <br/>
          &#x1f465; Betroffene: Besucher der Website<br/>
          &#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br/>
          &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten.
          Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br/>
          &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br/>
          &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
        </td>
      </tr>
      </tbody>
    </table>
    <h3>Was ist Webhosting?</h3>
    <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten &#8211;
      automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur
      mit
      Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain,
      d.h.
      alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir
      zum Beispiel beispiel.de oder musterbeispiel.com.</p>
    <p>Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser
      nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und
      Apple
      Safari.</p>
    <p>Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem
      Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel
      von
      professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine
      verlässliche und fehlerfreie Speicherung der Daten von Websites.</p>
    <p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Smartphone) und während der
      Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits
      speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen
      ordentlichen Betrieb zu gewährleisten.</p>
    <p>Zur Veranschaulichung:</p>
    <p>
      <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg"
           alt="Browser und Webserver" class="img-fluid"/>
    </p>
    <h3>Warum verarbeiten wir personenbezogene Daten?</h3>
    <p>Die Zwecke der Datenverarbeitung sind:</p>
    <ol>
      <li>Professionelles Hosting der Website und Absicherung des Betriebs</li>
      <li>zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
      <li>Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und
        ggf.
        zur Strafverfolgung bzw. Verfolgung von Ansprüchen
      </li>
    </ol>
    <h3>Welche Daten werden verarbeitet?</h3>
    <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem
      diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
    <ul>
      <li>die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B.
        https://www.beispielwebsite.de/beispielunterseite.html?tid=311831659)
      </li>
      <li>Browser und Browserversion (z. B. Chrome 87)</li>
      <li>das verwendete Betriebssystem (z. B. Windows 10)</li>
      <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.
        https://www.beispielquellsite.de/vondabinichgekommen.html/)
      </li>
      <li>den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B.
        COMPUTERNAME und 194.23.43.121)
      </li>
      <li>Datum und Uhrzeit</li>
      <li>in Dateien, den sogenannten Webserver-Logfiles</li>
    </ul>
    <h3>Wie lange werden Daten gespeichert?</h3>
    <p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben
      diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem
      Verhalten von Behörden eingesehen werden.</p>
    <p>
      <strong>Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere
      Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne
      Zustimmung weiter!</p>
    <h3>Rechtsgrundlage</h3>
    <p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs.
      1
      lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem
      Provider
      ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und
      Forderungen
      hieraus gegebenenfalls verfolgen zu können.</p>
    <p>Zwischen uns und dem Hostingprovider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art. 28
      f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.</p>
    <h2 id="web-analytics">Web Analytics</h2>
    <table border="1" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong>Web Analytics Datenschutzerklärung Zusammenfassung</strong>
          <br/>
          &#x1f465; Betroffene: Besucher der Website<br/>
          &#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br/>
          &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Gerätedaten,
          Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu
          finden Sie beim jeweils eingesetzten Web Analytics Tool.<br/>
          &#x1f4c5; Speicherdauer: abhängig vom eingesetzten Web-Analytics-Tool<br/>
          &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO
          (Berechtigte Interessen)
        </td>
      </tr>
      </tbody>
    </table>
    <h3>Was ist Web Analytics?</h3>
    <p>Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens der Website-Besucher, kurz Web Analytics
      oder Web-Analyse genannt. Dabei werden Daten gesammelt, die der jeweilige Analytic-Tool-Anbieter (auch
      Trackingtool
      genannt) speichert, verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen über das Nutzerverhalten auf
      unserer Website erstellt und uns als Websitebetreiber zur Verfügung gestellt. Zusätzlich bieten die meisten Tools
      verschiedene Testmöglichkeiten an. So können wir etwa testen, welche Angebote oder Inhalte bei unseren Besuchern
      am
      besten ankommen. Dafür zeigen wir Ihnen für einen begrenzten Zeitabschnitt zwei verschiedene Angebote. Nach dem
      Test
      (sogenannter A/B-Test) wissen wir, welches Produkt bzw. welcher Inhalt unsere Websitebesucher interessanter
      finden.
      Für solche Testverfahren, wie auch für andere Analytics-Verfahren, können auch Userprofile erstellt werden und die
      Daten in Cookies gespeichert werden.</p>
    <h3>Warum betreiben wir Web Analytics?</h3>
    <p>Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen für unsere Branche das besten Webangebot auf
      dem Markt liefern. Um dieses Ziel zu erreichen, wollen wir einerseits das beste und interessanteste Angebot bieten
      und andererseits darauf achten, dass Sie sich auf unserer Website rundum wohlfühlen. Mit Hilfe von
      Webanalyse-Tools
      können wir das Verhalten unserer Websitebesucher genauer unter die Lupe nehmen und dann entsprechend unser
      Webangebot für Sie und uns verbessern. So können wir beispielsweise erkennen wie alt unsere Besucher
      durchschnittlich sind, woher sie kommen, wann unsere Website am meisten besucht wird oder welche Inhalte oder
      Produkte besonders beliebt sind. All diese Informationen helfen uns die Website zu optimieren und somit bestens an
      Ihre Bedürfnisse, Interessen und Wünsche anzupassen.</p>
    <h3>Welche Daten werden verarbeitet?</h3>
    <p>Welche Daten genau gespeichert werden, hängt natürlich von den verwendeten Analyse-Tools ab. Doch in der Regel
      wird
      zum Beispiel gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf welche Buttons oder Links Sie
      klicken,
      wann Sie eine Seite aufrufen, welchen Browser sie verwenden, mit welchem Gerät (PC, Tablet, Smartphone usw.) Sie
      die
      Website besuchen oder welches Computersystem Sie verwenden. Wenn Sie damit einverstanden waren, dass auch
      Standortdaten erhoben werden dürfen, können auch diese durch den Webanalyse-Tool-Anbieter verarbeitet werden.</p>
    <p>Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen
      personenbezogene Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert (also in unkenntlicher und
      gekürzter Form) gespeichert. Für den Zweck der Tests, der Webanalyse und der Weboptimierung werden grundsätzlich
      keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder Ihre E-Mail-Adresse gespeichert. All diese
      Daten werden, sofern sie erhoben werden, pseudonymisiert gespeichert. So können Sie als Person nicht identifiziert
      werden.</p>
    <p>Das folgende Beispiel zeigt schematisch die Funktionsweise von Google Analytics als Beispiel für client-basiertes
      Webtracking mit Java-Script-Code.</p>
    <p>
      <img role="img" src="https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg"
           alt="Schematischer Datenfluss bei Google Analytics" class="img-fluid"/>
    </p>
    <p>Wie lange die jeweiligen Daten gespeichert werden, hängt immer vom Anbieter ab. Manche Cookies speichern Daten
      nur
      für ein paar Minuten bzw. bis Sie die Website wieder verlassen, andere Cookies können Daten über mehrere Jahre
      speichern.</p>
    <h3>
    <span
        data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wo und wie lange werden Daten gespeichert?&quot;}"
        data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Dauer der Datenverarbeitung</span>
    </h3>
    <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu
      haben.
      Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer
      Dienstleistungen
      und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben
      ist, kann diese Speicherdauer auch überschritten werden.</p>
    <h3>Widerspruchsrecht</h3>
    <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw.
      Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere
      Opt-Out-Funktionen. Zum Beispiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem
      Browser die Cookies verwalten, deaktivieren oder löschen.</p>
    <h3>Rechtsgrundlage</h3>
    <p>Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt
      haben.
      Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO
        (Einwilligung)</strong>
      die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics
      Tools vorkommen kann, dar.</p>
    <p>Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der
      Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von
      Web-Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die Wirtschaftlichkeit
      verbessern.
      Die Rechtsgrundlage dafür ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
        Interessen)</strong>. Wir setzen die Tools gleichwohl nur ein, soweit sie eine Einwilligung erteilt haben.</p>
    <p>Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir Ihnen auch das Lesen unserer allgemeinen
      Datenschutzerklärung zu Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden,
      sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
    <p>Informationen zu speziellen Web-Analytics-Tools, erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden
      Abschnitten.</p>
    <h2 id="cloudflare-datenschutzerklaerung">Cloudflare Datenschutzerklärung</h2>
    <table border="1" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong>Cloudflare Datenschutzerklärung Zusammenfassung</strong>
          <br/>
          &#x1f465; Betroffene: Besucher der Website<br/>
          &#x1f91d; Zweck: Optimierung unserer Serviceleistung (um die Website schneller laden zu können)<br/>
          &#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse, Kontakt- und Protokollinfos,
          Sicherheitsfingerabdrücke
          und Leistungsdaten für Websites<br/>
          Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br/>
          &#x1f4c5; Speicherdauer: meisten werden die Daten für weniger als 24 Stunden gespeichert<br/>
          &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO
          (Berechtigte Interessen)
        </td>
      </tr>
      </tbody>
    </table>
    <h3>Was ist Cloudflare?</h3>
    <p>Wir verwenden auf dieser Website Cloudflare der Firma Cloudflare, Inc. (101 Townsend St., San Francisco, CA
      94107,
      USA), um unsere Webseite schneller und sicherer zu machen. Dabei verwendet Cloudflare Cookies und verarbeitet
      User-Daten. Cloudflare, Inc. ist eine amerikanische Firma, die ein Content Delivery Network und diverse
      Sicherheitsdienste anbietet. Diese Dienste befinden sich zwischen dem User und unserem Hosting-Anbieter. Was das
      alles genau bedeutet, versuchen wir im Folgenden genauer zu erläutern.</p>
    <p>Ein Content Delivery Network (CDN), wie es die Firma Cloudflare bereitstellt, ist nichts anderes als ein Netzwerk
      aus verbundenen Servern. Cloudflare hat auf der ganzen Welt solche Server verteilt, um Webseiten schneller auf
      Ihren
      Bildschirm zu bringen. Ganz einfach gesagt, legt Cloudflare Kopien unserer Webseite an und platziert sie auf ihren
      eigenen Servern. Wenn Sie jetzt unsere Webseite besuchen, stellt ein System der Lastenverteilung sicher, dass die
      größten Teile unserer Webseite von jenem Server ausgeliefert werden, der Ihnen unsere Webseite am schnellsten
      anzeigen kann. Die Strecke der Datenübertragung zu Ihrem Browser wird durch ein CDN deutlich verkürzt. Somit wird
      Ihnen der Content unserer Webseite durch Cloudflare nicht nur von unserem Hosting-Server geliefert, sondern von
      Servern aus der ganzen Welt. Besonders hilfreich wird der Einsatz von Cloudflare für User aus dem Ausland, da hier
      die Seite von einem Server in der Nähe ausgeliefert werden kann. Neben dem schnellen Ausliefern von Webseiten
      bietet
      Cloudflare auch diverse Sicherheitsdienste, wie den DDoS-Schutz oder die Web Application Firewall an.</p>
    <h3>Warum verwenden wir Cloudflare auf unserer Website?</h3>
    <p>Natürlich wollen wir Ihnen mit unserer Webseite das bestmögliche Service bieten. Cloudflare hilft uns dabei,
      unsere
      Webseite schneller und sicherer zu machen. Cloudflare bietet uns sowohl Web-Optimierungen als auch
      Sicherheitsdienste, wie DDoS-Schutz und Web-Firewall, an. Dazu gehören auch ein <a
          href="https://de.wikipedia.org/wiki/Reverse_Proxy"
          target="_blank"
          rel="noopener noreferrer">Reverse-Proxy</a>
      und das Content-Verteilungsnetzwerk (CDN). Cloudflare blockiert Bedrohungen und begrenzt missbräuchliche Bots und
      Crawler, die unsere Bandbreite und Serverressourcen verschwenden. Durch das Speichern unserer Webseite auf lokalen
      Datenzentren und das Blockieren von Spam-Software ermöglicht Cloudflare, unsere Bandbreitnutzung etwa um 60% zu
      reduzieren. Das Bereitstellen von Inhalten über ein Datenzentrum in Ihrer Nähe und einiger dort durchgeführten
      Web-Optimierungen reduziert die durchschnittliche Ladezeit einer Webseite etwa um die Hälfte. Durch die
      Einstellung
      „I´m Under Attack Mode“ („Ich werde angegriffen“-Modus) können laut Cloudflare weitere Angriffe abgeschwächt
      werden,
      indem eine JavaScript-Rechenaufgabe angezeigt wird, die man lösen muss, bevor ein User auf eine Webseite zugreifen
      kann. Insgesamt wird dadurch unsere Webseite deutlich leistungsstärker und weniger anfällig auf Spam oder andere
      Angriffe.</p>
    <h3>Welche Daten werden von Cloudflare verarbeitet?</h3>
    <p>Cloudflare leitet im Allgemeinen nur jene Daten weiter, die von Webseitenbetreibern gesteuert werden. Die Inhalte
      werden also nicht von Cloudflare bestimmt, sondern immer vom Webseitenbetreiber selbst. Zudem erfasst Cloudflare
      unter Umständen bestimmte Informationen zur Nutzung unserer Webseite und verarbeitet Daten, die von uns versendet
      werden oder für die Cloudflare entsprechende Anweisungen erhalten hat. In den meisten Fällen erhält Cloudflare
      Daten
      wie IP-Adresse, Kontakt- und Protokollinfos, Sicherheitsfingerabdrücke und Leistungsdaten für Websites.
      Protokolldaten helfen Cloudflare beispielsweise dabei, neue Bedrohungen zu erkennen. So kann Cloudflare einen
      hohen
      Sicherheitsschutz für unsere Webseite gewährleisten. Cloudflare verarbeitet diese Daten im Rahmen der Services
      unter
      Einhaltung der geltenden Gesetze. Dazu zählt natürlich auch die Datenschutzgrundverordnung (DSGVO).</p>
    <p>Aus Sicherheitsgründen verwendet Cloudflare auch ein Cookie. Das Cookie (__cfduid) wird eingesetzt, um einzelne
      User hinter einer gemeinsam genutzten IP-Adresse zu identifizieren und Sicherheitseinstellungen für jeden
      einzelnen
      User anzuwenden. Sehr nützlich wird dieses Cookie beispielsweise, wenn Sie unsere Webseite aus einem Lokal
      benutzen,
      in dem sich eine Reihe infizierter Computer befinden. Wenn aber Ihr Computer vertrauenswürdig ist, können wir dies
      anhand des Cookies erkennen. So können Sie, trotz infizierter PCs in Ihrem Umfeld, ungehindert und sorgenfrei
      durch
      unsere Webseite surfen. Wichtig zu wissen ist auch noch, dass dieses Cookie keine personenbezogenen Daten
      speichert.
      Dieses Cookie ist für die Cloudflare-Sicherheitsfunktionen unbedingt erforderlich und kann nicht deaktiviert
      werden.</p>
    <h3>Cookies von Cloudflare</h3>
    <p>
      <strong>Name:</strong> __cfduid<br/>
      <strong>Wert:</strong> d798bf7df9c1ad5b7583eda5cc5e78311831659-3<br/>
      <strong>Verwendungszweck:</strong> Sicherheitseinstellungen für jeden einzelnen
      Besucher<br/>
      <strong>Ablaufdatum:</strong> nach einem Jahr</p>
    <p>Cloudflare arbeitet auch mit Drittanbietern zusammen. Diese dürfen personenbezogene Daten nur unter Anweisung der
      Firma Cloudflare und in Übereinstimmung mit den Datenschutzrichtlinien und anderer Vertraulichkeits- und
      Sicherheitsmaßnahmen verarbeiten. Ohne explizite Einwilligung von uns gibt Cloudflare keine personenbezogenen
      Daten
      weiter.</p>
    <h3>Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Cloudflare speichert Ihre Informationen hauptsächlich in den USA und im Europäischen Wirtschaftsraum. Cloudflare
      kann die oben beschriebenen Informationen aus der ganzen Welt übertragen und darauf zugreifen. Im Allgemeinen
      speichert Cloudflare Daten auf User-Ebene für Domains in den Versionen Free, Pro und Business für weniger als 24
      Stunden. Für Enterprise Domains, die Cloudflare Logs (früher Enterprise LogShare oder ELS) aktiviert haben, können
      die Daten bis zu 7 Tage gespeichert werden. Wenn allerdings IP-Adressen bei Cloudflare Sicherheitswarnungen
      auslösen, kann es zu Ausnahmen der oben angeführten Speicherungsdauer kommen.</p>
    <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Cloudflare bewahrt Daten-Protokolle nur solange wie nötig auf und diese Daten werden auch in den meisten Fällen
      innerhalb von 24 Stunden wieder gelöscht. Cloudflare speichert auch keine personenbezogenen Daten, wie
      beispielsweise Ihre IP-Adresse. Es gibt allerdings Informationen, die Cloudflare als Teil seiner permanenten
      Protokolle auf unbestimmte Zeit speichert, um so die Gesamtleistung von Cloudflare Resolver zu verbessern und
      etwaige Sicherheitsrisiken zu erkennen. Welche permanenten Protokolle genau gespeichert werden, können Sie auf <a
          href="https://www.cloudflare.com/application/privacypolicy/">https://www.cloudflare.com/application/privacypolicy/</a>
      nachlesen. Alle Daten, die Cloudflare sammelt (temporär oder permanent), werden von allen personenbezogenen Daten
      bereinigt. Alle permanenten Protokolle werden zudem von Cloudflare anonymisiert.</p>
    <p>Cloudflare geht in ihrer Datenschutzerklärung darauf ein, dass sie für die Inhalte, die sie erhalten nicht
      verantwortlich sind. Wenn Sie beispielsweise bei Cloudflare anfragen, ob sie Ihre Inhalte aktualisieren oder
      löschen
      können, verweist Cloudflare grundsätzlich auf uns als Webseitenbetreiber. Sie können auch die gesamte Erfassung
      und
      Verarbeitung Ihrer Daten durch Cloudflare komplett unterbinden, indem Sie die Ausführung von Script-Code in Ihrem
      Browser deaktivieren oder einen Script-Blocker in Ihren Browser einbinden.</p>
    <h3>Rechtsgrundlage</h3>
    <p>Wenn Sie eingewilligt haben, dass Cloudflare eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden
      Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong> Art. 6
        Abs.
        1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie
      bei der Erfassung durch Cloudflare vorkommen kann, dar.</p>
    <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Cloudflare zu verwenden, um unser Online-Service zu
      optimieren und sicherer zu machen. Die dafür entsprechende Rechtsgrundlage ist <strong>Art.
        6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Cloudflare gleichwohl nur ein, soweit Sie
      eine
      Einwilligung erteilt haben.</p>
    <p>Cloudflare verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen
      Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit
      verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
    <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union,
      Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet
      Cloudflare von der EU-Kommission genehmigte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese
      Klauseln
      verpflichten Cloudflare, das EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch außerhalb der EU
      einzuhalten. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss
      sowie die Klauseln u.a. hier: <a
          href="https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de"
          target="_blank" rel="follow noopener">https://ec.europa.eu/germany/news/20210604-datentransfers-eu_de</a>.
    </p>
    <p>Mehr Informationen zum Datenschutz bei Cloudflare finden Sie auf <a
        href="https://www.cloudflare.com/de-de/privacypolicy/?tid=311831659"
        target="_blank" rel="noopener noreferrer">https://www.cloudflare.com/de-de/privacypolicy/</a>
    </p>
    <p>Alle Texte sind urheberrechtlich geschützt.</p>
    <p style="margin-top:15px">
      Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/"
                                  title="Datenschutz Generator von AdSimple für Deutschland">Datenschutz
      Generator</a> von AdSimple</p>
  </div>
</template>

<script>
export default {
  name: "DataProtectionPolicy"
}
</script>

<style scoped>

</style>