import { render, staticRenderFns } from "./NewsCard.vue?vue&type=template&id=580d3b11&scoped=true&"
import script from "./NewsCard.vue?vue&type=script&lang=js&"
export * from "./NewsCard.vue?vue&type=script&lang=js&"
import style0 from "./NewsCard.vue?vue&type=style&index=0&id=580d3b11&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580d3b11",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VDivider,VFadeTransition,VIcon,VImg})
